import request from '@/utils/request.js'
export default {
  // 富友扫码支付
  fyMicroPay: (data) => {
    return request({
      url: '/pay/fyPayOrder/fyMicroPay',
      method: 'post',
      data
    })
  },
  // 富友退款申请
  fyOrderRefund: (data) => {
    return request({
      url: '/pay/fyPayOrder/fyOrderRefund',
      method: 'post',
      data
    })
  },
  // 富友查询订单状态
  fyOrderQuery: (data) => {
    return request({
      url: '/pay/fyPayOrder/fyOrderQuery',
      method: 'post',
      data
    })
  },
  // 微信退款申请
  wxOrderRefund: (data) => {
    return request({
      url: '/pay/wxPayOrder/wxOrderRefund',
      method: 'post',
      data
    })
  },
  // 微信扫码付款
  wxOrderScan: (data) => {
    return request({
      url: '/pay/wxPayOrder/wxOrderScan',
      method: 'post',
      data
    })
  },
  // 微信查询订单状态
  wxOrderQuery: (outTradeNo) => {
    return request({
      url: '/pay/wxPayOrder/wxOrderQueryScan/' + outTradeNo,
      method: 'post'
    })
  },
  // 微信扫码支付订单退款
  wxOrderRefundScan: (data) => {
    return request({
      url: '/pay/wxPayOrder/wxOrderRefundScan',
      method: 'post',
      data
    })
  }
}
