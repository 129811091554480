
import payInfo from '@/service/pay.js'
function pay (params) {
  return new Promise((resolve, reject) => {
    payInfo.wxOrderScan({
      authCode: params.authCode,
      outTradeNo: params.outTradeNo,
      actualPrice: params.actualPrice,
      orderInfoId: params.orderId
    }).then(res => {
      const result = JSON.parse(res.data)
      console.log(result)
      if (result.trade_state === 'SUCCESS') {
        resolve(result)
      } else {
        const setInt = setInterval(() => {
          payInfo.wxOrderQuery(params.outTradeNo).then((query) => {
            const queryResult = JSON.parse(query.data)
            if (queryResult.trade_state === 'SUCCESS' || queryResult.trade_state === 'CLOSED' || queryResult.trade_state === 'PAYERROR') {
              clearInterval(setInt)
              resolve(queryResult)
            } else if (queryResult.result_code === 'FAIL' || queryResult.return_msg === 'FAIL') {
              clearInterval(setInt)
              reject(res)
            }
          })
        }, 1000)
      }
    }).catch(res => {
      reject(res)
    })
  })
}

// 退款
async function refund (params) {
  const headSrt = params.outTradeNo.substr(0, 2)
  // 微信小程序支付
  if (headSrt === '42007AAB') {
    const res = await payInfo.wxOrderRefund({
      outTradeNo: params.outTradeNo,
      actualPrice: params.actualPrice,
      orderInfoId: params.orderInfoId,
      refundPrice: params.actualPrice,
      reason: '商品退款'
    })
    return res
  } else {
    const res = await payInfo.wxOrderRefundScan({
      outTradeNo: params.outTradeNo,
      actualPrice: params.actualPrice,
      orderInfoId: params.orderInfoId,
      refundPrice: params.actualPrice,
      reason: '商品退款'
    })
    return res
  }
}

export default {
  pay,
  refund
}
