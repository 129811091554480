
class Barcode {
  constructor ({ successCD }) {
    this.barcode = ''
    this.successCD = successCD
    this.createBarcode()
  }

 onmarked (type, result) {
    this.successCD(result)
    this.closeBarcodeComponent()
  }

  createBarcode () {
    if (!this.barcode) {
      const dom = document.getElementById('barcodeLoading')
      // eslint-disable-next-line no-undef
      this.barcode = plus.barcode.create('barcode', [plus.barcode.QR], {
        top: '0px',
        right: '0px',
        width: '100%',
        height: `${dom.offsetHeight}px`,
        position: 'static'
      })
      this.barcode.onmarked = (type, result) => this.onmarked(type, result)
      // eslint-disable-next-line no-undef
      plus.webview.currentWebview().append(this.barcode)
      console.log()
    }
    this.barcode.start()
  }

  closeBarcodeComponent () {
    if (!this.barcode) {
      alert('尚未开始扫码')
    }
    this.barcode.cancel()
    this.barcode.close()
    this.barcode = null
  }
}

export default Barcode
