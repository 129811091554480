function orderType (authCode) {
   const headSrt = authCode.substr(0, 2)
   console.log(headSrt)
   if (headSrt >= 10 && headSrt <= 15) {
    return 'WECHAT'
   }
   if (headSrt >= 25 && headSrt <= 30) {
    return 'ALIPAY'
   }
}

export default orderType
