<template>
  <div class="barcode">
    <div class="barcode__loading" id="barcodeLoading">
      <i class="el-icon-loading"></i>
    </div>
    <div class="barcode__bottom">
      <div class="barcode__bottom--button" @click="closeBarcodeComponent">
        取消扫描
      </div>
    </div>
  </div>
</template>
<script>
import Barcode from '@/utils/barcode.js'
import uniPay from '@/utils/pay.js'
import orderType from '@/utils/orderType.js'
import orderInfo from '@/service/orderInfo.js'
import { mapMutations, mapState } from 'vuex'
import Decimal from 'decimal.js'
export default {
  data () {
    return {
      orderId: '',
      businessTableMark: '',
      businessTableId: '',
      businessAreaId: '',
      persontNum: '',
      invoicingPay: false,
      barCodeDom: '',
      orderData: {},
      addFood: false
    }
  },
  computed: {
    ...mapState({
      card: (store) => store.buyCard.card,
      shopInfo: (store) => store.login.shopInfo
    }),
    orderLs () {
      return this.card[this.shopInfo.id]
    }
  },
  created () {
    console.log('---------')
    console.log(this.$route.params)
    this.orderId = this.$route.params.orderId
    this.invoicingPay = this.$route.params.invoicingPay
    this.businessTableMark = this.$route.params.businessTableMark
    this.businessTableId = this.$route.params.businessTableId
    this.businessAreaId = this.$route.params.businessAreaId
    this.persontNum = this.$route.params.persontNum
    this.addFood = this.$route.params.addFood
  },
  mounted () {
    if (navigator.userAgent.indexOf('Html5Plus') > -1) {
      const that = this
      // eslint-disable-next-line no-unused-vars
      this.barCodeDom = new Barcode({
        successCD: (authCode) => that.successCD(authCode)
      })
    }
  },
  methods: {
    ...mapMutations({
      resetCard: 'buyCard/resetCard'
    }),
    closeBarcodeComponent () {
      this.barCodeDom.closeBarcodeComponent()
      this.$router.back()
    },
    async successCD (authCode) {
      // 生成订单或者加餐
      if (!this.orderId || this.addFood) {
        // 加餐
        if (this.addFood && this.orderId) {
          await this.getOrderInfo()
        }
        this.orderId = await this.createOrderFun()
      }
      // 生成订单号
      const outTradeNo = Math.floor(Math.random() * 9999) * Date.now() + ''
       // 查询订单详情
      const res = await orderInfo.getOrderById(this.orderId)
      this.orderData = res.data
      // 发起支付
      const payRes = await uniPay.pay({
        authCode: authCode,
        outTradeNo: outTradeNo,
        actualPrice: res.data.actualPrice,
        orderId: this.orderId
      })
        // 支付成功
      if (payRes.result_msg === 'SUCCESS') {
        // 修改支付类型
        const editOrdeRres = await orderInfo.editOrderPayType({
          id: res.data.id,
          outTradeNo: outTradeNo,
          payType: orderType(authCode) === 'WECHAT' ? 1 : 2
        })
        // 修改支付状态
        if (editOrdeRres.success) {
          await orderInfo.changeOrderStatus({
            id: res.data.id,
            orderStatus: this.invoicingPay ? 3 : 1
          })
        }
        // 下单同时结账，通知打印机
        if (!this.invoicingPay) {
          this.printOrder({
            orderId: res.data.id,
            voice: 2
          })
        }
      } else {
        this.$message({
          message: '支付失败',
          type: 'warning'
        })
        // 单点同时结账支付失败并且不是加餐的操作
        if (!this.invoicingPay && !this.addFood) {
          await orderInfo.changeOrderStatus({
            id: this.orderId,
            orderStatus: 4
          })
        }
      }
      // 加餐成功返回上一步
      if (this.addFood) {
        this.$router.go(-2)
      } else {
        this.$router.back()
      }
      // 结账发送时间总线
      if (this.invoicingPay) {
        this.$eventBus.$emit('invoicing', this.orderData)
      }
      this.resetCard(this.shopInfo.id)
    },
    // 创建订单
    async createOrderFun () {
      let orderFoodDtoList = this.orderLs.map((item) => {
        const foodProp =
          typeof item.foodProp === 'string'
            ? JSON.parse(item.foodProp)
            : item.foodProp
        const ls = []
        for (const i in foodProp) {
          const obj = {}
          obj.name = i
          obj.specsList = [foodProp[i]]
          ls.push(obj)
        }
        return {
          foodId: item.id,
          foodNum: item.count,
          foodName: item.foodName,
          foodOriginalPrice: item.foodOriginalPrice,
          foodActualPrice: item.foodConcessionalPrice,
          foodPropDtoList: ls,
          foodImgUrl: item.foodImgUrl,
          foodTypeId: item.foodTypeId,
          businessAreaId: item.businessAreaId,
          isNew: 1
        }
      })
      const oldOrderFoodDtoList = this.orderData.orderFoodDtoList
        ? this.orderData.orderFoodDtoList
        : []
      orderFoodDtoList = [...orderFoodDtoList, ...oldOrderFoodDtoList]
      // 计算实际价格
      const actualPrice = orderFoodDtoList.reduce((pre, item) => {
        const count = new Decimal(item.foodNum)
        const foodActualPrice = new Decimal(item.foodActualPrice).mul(
          new Decimal(count)
        )
        return new Decimal(pre).add(foodActualPrice).toNumber().toFixed(2)
      }, 0)
      // 计算原来的价格
      const originalPrice = orderFoodDtoList.reduce((pre, item) => {
        const count = new Decimal(item.foodNum)
        const foodOriginalPrice = new Decimal(item.foodOriginalPrice).mul(
          new Decimal(count)
        )
        return new Decimal(pre).add(foodOriginalPrice).toNumber().toFixed(2)
      }, 0)

      // 生成请求主体
      const data = {
        ...this.orderData,
        actualPrice: actualPrice,
        businessId: this.shopInfo.id,
        businessTableMark: this.businessTableMark,
        businessTableId: this.businessTableId,
        businessAreaId: this.businessAreaId,
        orderFoodDtoList,
        remarks: this.remark,
        repastUserNum: this.persontNum,
        originalPrice: originalPrice
      }
      console.log(data)
      // 生成订单
      const res = await orderInfo.orderInfoSave(data)
      return res.data
    },
    // 查询订单详情
    async getOrderInfo () {
      const res = await orderInfo.getOrderById(this.orderId)
      const orderFoodDtoList = res.data.orderFoodDtoList.map((item) => {
        return {
          ...item,
          isNew: 0
        }
      })
      const orderData = {
        ...res.data,
        orderFoodDtoList
      }
      delete orderData.orderFoodListStr
      this.orderData = orderData
      return this.orderData
    }
  }
}
</script>
<style lang="scss" scoped>
.barcode {
  position: relative;
  height: 100vh;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__bottom {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 20px;
    &--button {
      background: #ffbb12;
      border-radius: 8px;
      width: 144px;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #000000;
      font-weight: 700;
      font-size: 14px;
    }
  }
}
#barcodeLoading {
  flex: 1;
  min-height: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffbb12;
  font-size: 80px;
}
</style>
